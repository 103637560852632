Reset some default browser styles
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Arial', sans-serif; /* Use a common sans-serif font */
    line-height: 1.6;
    color: #333;
    background-color: #f4f4f4; /* Light grey background */
}

/* Container for all content, to provide consistent padding */
.container {
    width: 80%;
    margin: auto;
    max-width: 1200px;
}

/* Header styles */
header {
    background: #333;
    color: #fff;
    padding: 10px 0;
    text-align: center;
}

header nav {
    display: flex;
    justify-content: center;
    gap: 20px;
}

header nav a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
}

header nav a:hover {
    text-decoration: underline;
}

/* Main content and section styling */
main {
    padding: 20px 0;
}

section {
    margin-bottom: 20px;
}

/* Styling for form elements in ContactForm */
input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

button, .btn {
    display: inline-block;
    background: #5C7AEA; /* A nice shade of blue */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

button:hover, .btn:hover {
    background: #3e5ec7; /* A darker shade of blue for hover state */
}

/* Utility classes for text alignment */
.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

/* Footer styles */
footer {
    background: #333;
    color: white;
    text-align: center;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
}

/* Responsive design for smaller screens */
@media only screen and (max-width: 768px) {
    .container, header nav {
        width: 90%;
    }

    header nav {
        flex-direction: column;
    }

    header nav a {
        margin: 10px 0;
    }
}
